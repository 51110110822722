:root {
  --header-height: 3.4rem;
  --first-color: #9e81a6;
  --first-color-alt: #5a3f68;
  --first-color-light: #d9c5d4;
  --first-color-lighten: hsl(228, 100%, 97%);
  --second-color: #9fbfb9;
  --title-color: #5a3f68;
  --text-color: hsl(228, 15%, 50%);
  --text-color-light: #d9c5d4;
  --border-color: hsl(228, 99%, 98%);
  --body-color: #fff;
  --container-color: #fff;

  --body-font: sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --z-tooltip: 10;
  --z-fixed: 100;
}

.grid {
  display: grid;
}

.main {
  overflow: hidden;
}

.section {
  padding: 4.5rem 0 2rem;
}

.home {
  background-image: url("../../../public/img/home.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
}

@media screen and (max-width: 425px){
  .home__description {
    display: none;
  }
  .home__value span, .home__value-number {
    display: none;
  }
  .home {
    height: min-content;
  }
}

.home__container {
  row-gap: 3rem;
}

.home__title,
.home__value-number {
  color: var(--first-color);
}

.home__title {
  font-size: var(--biggest-font-size);
  line-height: 120%;
  margin-bottom: 1.25rem;
}

.home__description {
  color: #9e81a6;
  margin-bottom: 2rem;
}

.home__value {
  display: flex;
  column-gap: 2.5rem;
  justify-content: center;
}

.home__value-number {
  font-size: var(--h1-font-size);
  font-weight: var(--font-medium);
}

.home__value-number span {
  color: var(--second-color);
}

.home__value-description {
  display: flex;
  color: var(--second-color);
  font-size: var(--small-font-size);
}

.home__data {
  background-color: #423838d7;
  padding: 1rem;
}