.hdr {
  text-align: center;
  padding: 0 10px;
  margin-top: 100px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1rem 10%;
}

.column {
  -ms-flex: 33%;
  flex: 33%;
  max-width: 33%;
  padding: 0 2.5rem;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 10px;
  }
}
