.container {
  max-width: 1024px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
}

.section {
  padding: 4.5rem 0 2rem;
}

/* Footer------ */
.footer__container {
  column-gap: 2.5rem;
}

.footer__logo {
  color: var(--first-color);
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  margin-bottom: 0.75rem;
}

.footer__title {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.footer__logo box-icon {
  font-size: 1.25rem;
}

.footer__description,
.footer__link {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.footer__content,
.footer__links {
  display: grid;
}

.footer__content {
  grid-template-columns: repeat(2, max-content);
  gap: 2.5rem 4rem;
  margin: 1rem 2rem;
}

.footer__links {
  row-gap: 0.5rem;
}

.footer__link {
  color: var(--text-color);
  transition: 0.3s;
}

.footer__link:hover {
  color: var(--title-color);
}

.footer__social {
  display: flex;
  column-gap: 1rem;
}

.footer__social-link {
  font-size: 1.25rem;
  color: var(--text-color);
  transition: 0.3s;
}

.footer__social-link:hover {
  color: var(--title-color);
}

.footer__info {
  display: flex;
}

.footer__info {
  padding-bottom: 6rem;
  margin-top: 5.5rem;
  flex-direction: column;
  text-align: center;
  row-gap: 1.5rem;
}
