:root {
  --header-height: 3.4rem;
  --first-color: #9E81A6;
  --first-color-alt: #5A3F68;
  --first-color-light: #D9C5D4;
  --first-color-lighten: hsl(228, 100%, 97%);
  --second-color: #9FBFB9;
  --title-color: #5A3F68;
  --text-color: hsl(228, 15%, 50%);
  --text-color-light: #D9C5D4;
  --border-color: hsl(228, 99%, 98%);
  --body-color: #fff;
  --container-color: #fff;

  --body-font: sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --z-tooltip: 10;
  --z-fixed: 100;
}

/* opcion de filtrado */
.botones-propiedades {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.boton-estado {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  color: var(--first-color-alt);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px;
  padding: 8px 15px;
  transition: all 0.3s ease;
}

.boton-estado:hover {
  background-color: var(--first-color);
  color: #fff;
}

.seleccionado {
  background-color: #333;
  color: #fff;
}


.container {
  max-width: 1024px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.section {
  padding: 4.5rem 0 2rem;
  margin-top: 5px;
  justify-content: center;
}

.section--subtitle {
  display: block;
  font-size: var(--small-font-size);
  color: var(--second-color);
}

.popular__container {
  padding: 1rem 0 2rem;
}

.popular__card {
  width: 290px;
  background-color: var(--container-color);
  padding: 0.5rem 0.5rem 1.5rem;
  border-radius: 1rem;
  margin: 0 auto;
  transition: 0.4s;
}

.popular__img {
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.popular__data {
  padding: 0 1rem 0 0.5rem;
}

.popular__price {
  font-size: var(--h2-font-size);
  color: var(--text-color);
  margin-bottom: 0.25rem;
}

.popular__price span {
  color: var(--second-color);
}

.popular__title {
  font-size: var(--h3-font-size);
  margin-bottom: 0.75rem;
}

.nombre-propiedad:webkit-any-link {
  color: var(--second-color);
}
a.nombre-propiedad{
  color: var(--first-color-alt);
}

.popular__description {
  font-size: var(--small-font-size);
}

.popular__card:hover {
  box-shadow: 0 12px 16px hsla(228, 66%, 45%, 0.1);
}

.next-prev {
  display: flex;
  justify-content: center;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

.swiper-next,
.swiper-prev {
  top: initial;
  bottom: 0;
  width: initial;
  height: initial;
  margin-right: 1rem;
  background-color: var(--container-color);
  border: 2px solid var(--text-color-light);
  padding: 6px;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  color: var(--first-color);
}

.swiper-prev {
  left: calc(50% - 3rem);
}

.swiper-next {
  right: calc(50% - 3rem);
}

.popular__card.vendida {
  opacity: 0.6;
  position: relative;
}

.vendida-banner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 24px;
  font-weight: bold;
  z-index: 10;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}
