:root {
  --header-height: 3.4rem;
  --first-color: #9e81a6;
  --first-color-alt: #5a3f68;
  --first-color-light: #d9c5d4;
  --first-color-lighten: hsl(228, 100%, 97%);
  --second-color: #9fbfb9;
  --title-color: #5a3f68;
  --text-color: hsl(228, 15%, 50%);
  --text-color-light: #d9c5d4;
  --border-color: hsl(228, 99%, 98%);
  --body-color: #fff;
  --container-color: #fff;

  --body-font: sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Responsive typography */
@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: 0.3s;
  margin: 0;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input,
button {
  font-family: var(--body-font);
  outline: none;
  border: none;
}

/* @media */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .section {
    padding: 3.5rem 0 1rem;
  }

  .home {
    padding-bottom: 0;
  }

  .contact__card {
    grid-template-columns: repeat(1, 180px);
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .nav__menu {
    padding: 1.3rem 1.5rem;
  }

  .home__value {
    column-gap: 1rem;
  }

  .popular__card {
    width: 230px;
    padding: 0.5rem 0.5rem 0.75rem;
  }

  .contact__img {
    width: 220px;
    height: 260px;
  }

  .contact__orbe {
    width: 236px;
    height: 280px;
  }
  .footer__content {
    gap: 2.5rem;
  }

}

@media screen and (min-width: 576px) {
  .nav__menu {
    width: 342px;
  }
  
  .contact__card {
    grid-template-columns: repeat(2, 192px);
    justify-content: center;
  }

  .footer__content {
    grid-template-columns: repeat(3, max-content);
  }
  

}

@media screen and (min-width: 767px) {
  .home__container {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 2rem;
  }

  .home__data {
    padding-bottom: 2rem;
  }

  .contact__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .footer__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
  }
  
}

@media screen and (max-width: 765px){
  .contact__img {
    display: none;
  }
  .contact__container {
    background-image: url("../public/img/contact2.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 7.5rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }

  .section__title {
    font-size: 2.25rem;
  }

  .section--subtitle {
    font-size: var(--normal-font-size);
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__menu {
    width: initial;
    margin-left: auto;
  }

  .nav__list {
    display: flex;
    column-gap: 3rem;
  }

  .nav__link {
    color: var(--text-color-light);
  }

  .nav__link i {
    display: none;
  }

  .nav__button {
    display: inline-block;
  }

  .scroll-header .nav__link {
    color: var(--text-color);
  }

  .scroll-header .active-link {
    color: var(--first-color);
  }

  .home {
    padding-bottom: 0;
  }

  .home__container {
    /* padding-top: 1rem; */
    column-gap: 2rem;
  }

  .home__data {
    padding-bottom: 4rem;
  }

  .home__title {
    margin-bottom: 2rem;
  }

  .home__description {
    margin-bottom: 3rem;
  }

  .home__value {
    column-gap: 3.5rem;
    justify-content: center;
  }

  .popular__container {
    padding-top: 3rem;
  }

  .popular__card {
    width: 320px;
    padding: 0.75rem 0.75rem 2rem;
  }

  .popular__data {
    padding: 0 0.25rem 0 0.75rem;
  }

  .contact__container {
    align-items: flex-start;
    column-gap: 5rem;
  }

  .contact__orbe {
    width: 501px;
    height: 641px;
    border-radius: 258px 258px 16px 16px;
  }

  .contact__img {
    width: 461px;
    height: 601px;
    border-radius: 238px 238px 12px 12px;
  }

  .contact__img img {
    /* max-width: initial; */
    width: 490px;
  }

  .contact__description {
    font-size: var(--normal-font-size);
    margin-bottom: 2.5rem;
  }

  .contact__card {
    grid-template-columns: repeat(2, 200px);
  }

  .contact__card-box {
    padding: 28px 1.5rem 1.5rem;
  }

  .footer__content {
    grid-template-columns: repeat(4, max-content);
  }

  .footer__title {
    margin-bottom: 1.5rem;
  }

  .footer__links {
    row-gap: 1rem;
  }

  .footer__info {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1040px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0;
  }

  .home__container {
    column-gap: 4rem;
  }
}

@media screen and (min-width: 2048px) {
  body {
    zoom: 1.5;
  }
}

@media screen and (min-width: 3840px) {
  body {
    zoom: 2;
  }
}

::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: hsl(228, 8%), 76%;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(228, 8%, 64%);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(228, 8%, 54%);
}
