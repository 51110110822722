:root {
  --header-height: 3.4rem;
  --first-color: #9e81a6;
  --first-color-alt: #5a3f68;
  --first-color-light: #d9c5d4;
  --first-color-lighten: hsl(228, 100%, 97%);
  --second-color: #9fbfb9;
  --title-color: #5a3f68;
  --text-color: hsl(228, 15%, 50%);
  --text-color-light: #d9c5d4;
  --border-color: hsl(228, 99%, 98%);
  --body-color: #fff;
  --container-color: #fff;

  --body-font: sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --font-medium: 500;
  --font-semi-bold: 600;

  --z-tooltip: 10;
  --z-fixed: 100;
}
/* link  activo */
.nav__item.active .nav__link {
  color: var(--first-color-alt); /* Cambia el color del texto al azul */
}

.nav__item.active box-icon {
  /* color: var(--first-color); Cambia el color del ícono al azul */
  transform: scale(1.2); /* Hace el ícono un poco más grande */
  border: var(--first-color) 2px solid;
  border-radius: 50%;
  background-color: #9e81a6;
}



.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--first-color);
  z-index: var(--z-fixed);
  transition: 0.4s;
}

.nav {
  /* height: var(--header-height); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  color: var(--second-color);
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav__logo box-icon {
  font-size: 1rem;
}

.nav__logo:hover {
  color: var(--first-color);
}

@media screen and (max-width: 1023px) {
  .nav__menu {
    position: fixed;
    bottom: 2rem;
    background-color: var(--container-color);
    box-shadow: 0 8px 24px hsla(228, 66%, 45%, 0.15);
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 1.3rem 3rem;
    border-radius: 1.25rem;
    transition: 0.4s;
  }

  .nav__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav__link {
    color: var(--text-color);
    display: flex;
    padding: 0.5rem;
    border-radius: 50%;
  }

  .nav__link box-icon {
    font-size: 1.25rem;
  }

  .nav__link span {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .section {
    padding: 7.5rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section__title {
    font-size: 2.25rem;
  }

  .section--subtitle {
    font-size: var(--normal-font-size);
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__menu {
    width: initial;
    margin-left: auto;
  }

  .nav__list {
    display: flex;
    column-gap: 3rem;
  }

  .nav__link {
    color: var(--text-color-light);
  }

  .nav__link box-icon {
    display: none;
  }

  .nav__button {
    display: inline-block;
  }
}

@media screen and (max-width: 768px){
  .nav {
    height: var(--header-height);
  }
}